import React from 'react';
import { LabelProps } from './FormProps';

export const defaultClassName = 'block font-medium text-black';
export const Label = ({ id, labelText, className }: LabelProps) => {
	return (
		<label htmlFor={id} className={className || defaultClassName}>
			{labelText}
		</label>
	);
};
