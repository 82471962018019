import React from 'react';
import { ErrorProps } from './FormProps';

export const ErrorMessage = ({ id, errorMessage, className }: ErrorProps) => {
	return (
		<p id={id} role="alert" className={`text-red-500 italic ${className && className}`}>
			{errorMessage}
		</p>
	);
};
